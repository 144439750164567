.ui.styled.accordion .accordion .title, .ui.styled.accordion .title .n-accordion {
  font-family: 'Poppins';
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.25rem;
  color: #575757;
}

.ui.styled.accordion .active.title .n-accordion {
  color: #000000;
}

.ui.accordion .accordion .title .dropdown.icon, .ui.accordion .title .dropdown.icon {
  flex: none;
  margin-right: 14px;
}

.ui.styled.accordion .content {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25rem;
  margin-left: 34px;
}

.ui.styled.accordion .content ul {
  list-style-type: disc;
  margin-left: 20px;
  /* Copying over important Reboot styles for later Bootstrap retirement */
  margin-bottom: 1rem;
}

.n-accordion-flex {
  display: flex;
}

/* Copying over important Reboot styles for later Bootstrap retirement */
.ui.styled.accordion .content p {
  margin-bottom: 1rem;
}

/* Copying over important Reboot styles for later Bootstrap retirement */
.ui.styled.accordion .content p:last-child {
  margin-bottom: 0rem;
}
