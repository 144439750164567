.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.card-img {
  width: 100%;
  object-fit: cover;
  overflow: hidden;
}

.jumbotron {
  margin-top: 20px;
  background-image: url("https://images.unsplash.com/photo-1491438590914-bc09fcaaf77a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1050&q=80");
  background-size: cover;
  color: white;
}

.featurette-divider {
  margin: 40px auto;
}

.container-marketing {
  margin-top: 40px;
}

.star-rating {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.star {
  cursor: pointer;
  width: 1em;
  height: 1em;
  background-color: grey;
  -webkit-clip-path: polygon(
    50% 0%,
    63% 38%,
    100% 38%,
    69% 59%,
    82% 100%,
    50% 75%,
    18% 100%,
    31% 59%,
    0% 38%,
    37% 38%
  );
  clip-path: polygon(
    50% 0%,
    63% 38%,
    100% 38%,
    69% 59%,
    82% 100%,
    50% 75%,
    18% 100%,
    31% 59%,
    0% 38%,
    37% 38%
  );
}

.star.selected {
  background-color: yellow;
}

.leaflet-container {
  height: 50vw;
  max-height: 600px;
  width: 100%;
  z-index: 1;
}

ul.list-group.search {
  overflow: auto;
  height: 50vw;
  max-height: 600px;
}
.ui.dropdown .menu>.input:not(.transparent) input {
  padding:0;
}
.ui.input.search-radius>input[name="radius"] {
  margin-right: 2em;
}

.convo {
  overflow: auto;
  height: 50vh;
  max-height: 600px;
  position: relative;
}

.messages {
  overflow-y: scroll;
  height: 40vh;
  margin-top: 0px;
  max-height: 500px;
  position: relative;
}
.chatbar {
  width: 100%;
  position: relative;
}
.ui.form textarea.chatbar-textarea {
  resize: none;
  overflow: auto;
  height: auto;
}
.chat-header {
  width: 100%;
  top: 0px;
}

.comment .content .text {
  min-height: 1em;
}

.ui.modal {
  height: auto;
  position: static;
}